/**
 * @param callback function to be called when a scroll happen
 */
function scrollListener(callback) {
  // We throttle the events to avoid slowing down the browser
  // Code adapted from https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
  let ticking = false;

  document.addEventListener('scroll', () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        callback();
        ticking = false;
      });
      ticking = true;
    }
  });
}

export default scrollListener;
