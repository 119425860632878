function initDatepickers() {
  document.querySelectorAll('[data-datepicker-for]').forEach((datepicker) => {
    const name = datepicker.dataset.datepickerFor;
    const dateInput = document.getElementById(name);
    // eslint-disable-next-line no-new,no-undef
    new Datepicker({
      disableDatesBefore: dateInput.dataset.disableDatesBefore,
      name,
      selected_date: dateInput.value,
    });
  });
}

// Main
document.addEventListener('turbolinks:load', initDatepickers);
