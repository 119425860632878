class window.LiveEvent
  constructor: (@event, @selector, @event_callback, @refresh_callback) ->
    @init()
    @refresh({detail: {node: document}})

  init: ->
    document.addEventListeners @event, (event) =>
      if event.composedPath
        path = event.composedPath()
      else
        path = []
        currentNode = event.target
        while currentNode != document
          path.push currentNode
          currentNode = currentNode.parentNode

      match = path.some (e) =>
        match_function = e.matches or e.msMatchesSelector
        match_function.call(e, @selector) if e.tagName

      if match
        @event_callback.call(this, event.target)

    document.addEventListener 'live_event:refresh', @refresh

  refresh: (event) =>
    targets = event.detail.node.querySelectorAll(@selector)
    for target in targets
      do (target) =>
        @refresh_callback.call(this, target)
