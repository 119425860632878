listenProPaperForm = ->
  specific_pro_input = document.querySelector '[value=specific_professional]'

  form = document.querySelector '.js-form-with-pro-status'

  other_status_inputs = (document.querySelectorAll '.js-status-selector input[type="radio"]:not([value="specific_professional"])')

  specific_pro_input.addEventListener 'change', ->
    form.disable()

  for input in other_status_inputs
    input.addEventListener 'change', ->
      form.enable()

  form.disable() if specific_pro_input.checked

# Main
document.addEventListeners 'turbolinks:load', ->
  if (document.querySelector '.js-form-with-pro-status')
    listenProPaperForm()
