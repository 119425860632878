listenElectricMeter = ->
  input_true = document.querySelector '#subscription_definitive_electric_meter_true'
  input_false = document.querySelector '#subscription_definitive_electric_meter_false'
  alert = document.querySelector '#electric-meter-info'

  input_true.addEventListener 'change', -> alert.disable()
  input_false.addEventListener 'change', -> alert.enable()

listenConsuel = ->
  input_true = document.querySelector '#subscription_consuel_certificate_true'
  input_false = document.querySelector '#subscription_consuel_certificate_false'
  form = document.querySelector '#subscription-form'
  alert = document.querySelector "#consuel-certificate-info"

  input_true.addEventListener 'change', ->
    form.enable()
    alert.enable()

  input_false.addEventListener 'change', ->
    form.disable()
    alert.enable()

  form.disable() if input_false.checked
  alert.enable() if input_false.checked || input_true.checked

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector '#subscription-form.housing-new-site'
    listenElectricMeter()
    listenConsuel()
