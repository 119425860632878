// This component enables or disables an element if some buttons are checked
document.addEventListeners('turbolinks:load', () => {
  document.querySelectorAll('.js-status-selector').forEach((statusSelector) => {
    const updateProDetailsField = () => {
      const proDetails = document.querySelectorAll('.js-pro-details');
      const proStatus = document.querySelector('[value=professional]');
      const specificProStatus = document.querySelector('[value=specific_professional]');
      if (proStatus.checked || specificProStatus.checked) {
        proDetails.forEach((details) => (details.enable()));
      } else {
        proDetails.forEach((details) => (details.disable()));
      }
    };

    updateProDetailsField();
    statusSelector.addEventListener('change', updateProDetailsField);
  });
});
