EVENTS=
  addEventListeners: (element, eventNames, listener) ->
    events = eventNames.split(' ')
    for event in events
      element.addEventListener event, listener, false

Utility.extend EVENTS

Node.prototype.addEventListeners = (eventNames, listener) ->
  Utility.addEventListeners this, eventNames, listener

NodeList.prototype.addEventListener = (eventName, callback, useCapture) ->
  i = 0
  while i < @length
    @[i].addEventListener eventName, callback, useCapture
    i++
