class LeaveModal
  constructor: ->
    @modal = new Modal(document.querySelector("#leave-modal"), {name: 'leave-modal'})

    @init()
    @active = false

  init: ->
    document.addEventListener 'mouseout', (e) =>
      @open_modal() if e.clientY <= 0

    document.addEventListener 'mouseover', (e) =>
      @close_modal()

  open_modal: ->
    @modal.open()
    @active = true

  close_modal: ->
    if @active
      @modal.close(animation: true)
      @active = false

class SubscriptionFormShortcut
  constructor: ->
    @btn = document.querySelector('#subscription-form-shortcut a')
    @target = document.querySelector('#subscription-form')
    @init()

  init: ->
    @btn.addEventListener 'click', (e) =>
      e.preventDefault()
      @target.scrollIntoView({behavior: 'smooth'})

autoPlayVideo = ->
  document.querySelector('#video').play()

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector '[data-controller=subscriptions][data-state=onboarding]'
    autoPlayVideo()
    new LeaveModal
    new SubscriptionFormShortcut
    Utility.pushData 'Formulaire de souscription', 'Etapes clefs', 'onboarding-site'
