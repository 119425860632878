VISIBILITY =
  enable: (elt) ->
    elt.classList.remove 'inactive'
    elt.classList.add 'active'

  disable: (elt) ->
    elt.classList.remove 'active'
    elt.classList.add 'inactive'

Utility.extend VISIBILITY

Node.prototype.enable = -> Utility.enable this
Node.prototype.disable = -> Utility.disable this
