Routes = require('../routes.js.erb')

zipcodeCallback = (node) ->
  city_target = node.dataset.citiesTarget
  cities_input = document.querySelector("##{ city_target }")

  if node.value.match /^\d{5}$/
    loadCities value: node.value, input: cities_input
  else
    clearCitiesOptionList input: cities_input

zipcodeInit = (node) ->
  zipcodeCallback(node)

loadCities = (options) ->
  xhr = new XMLHttpRequest()
  spinner = document.querySelector '.spinner'
  spinner.enable()
  xhr.open 'GET', Routes.api_v1_cities_path(zipcode: options.value)
  xhr.send(null)

  xhr.onreadystatechange = ->
    spinner.disable()
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      data = JSON.parse(xhr.responseText).data
      clearCitiesOptionList input: options.input
      fillCitiesOptionList input: options.input, data: data
      Utility.preselectOptionList options.input

setSubmitDisabled = (options, bool) ->
  onboarding_form = options.input.closest('form[class*=onboarding]')
  if onboarding_form
    if bool
      onboarding_form.querySelector('[type=submit]').setAttribute('disabled', '');
    else
      onboarding_form.querySelector('[type=submit]').removeAttribute('disabled')

clearCitiesOptionList = (options) ->
  options.input.options.length = 0
  options.input.disabled = true
  setSubmitDisabled(options, true)

fillCitiesOptionList = (options) ->
  for city in options.data
    options.input.appendChild(option = new Option(city.attributes.name_with_article, city.attributes.insee))
    option.setAttribute('data-eld', city.attributes.eld)

  first_not_eld_option = options.input.querySelector("[data-eld=false]")
  first_not_eld_option.selected = true if first_not_eld_option

  options.input.disabled = options.data.length <= 0
  if options.input.afterFillCities
    options.input.afterFillCities()
  setSubmitDisabled(options, false)

# Main
document.addEventListeners 'turbolinks:load', ->
  new LiveEvent 'input', '[data-cities-target]', zipcodeCallback, zipcodeInit
