TRACKING =
  pushData: (category, action, label) ->
    data =
      'event': 'genericEvent',
      'getCategory': category,
      'getAction': action,
      'getLabel': label
    if typeof dataLayer != 'undefined'
      dataLayer.push data
    if typeof _mtm != 'undefined'
      _mtm.push data

Utility.extend TRACKING
