listenConsoOrReleveInputs = ->
  conso_or_releve_inputs = document.querySelectorAll('.js__conso_or_releve')
  conso_or_releve_inputs.forEach (conso_or_releve_input) ->
    conso_or_releve_input.eventHandler = ->
      forceNumeric(conso_or_releve_input)

    conso_or_releve_input.eventHandler()
    conso_or_releve_input.addEventListener 'input', conso_or_releve_input.eventHandler
    return

forceNumeric = (conso_or_releve_input) ->
  if conso_or_releve_input.value != ''
    conso_or_releve_input.value = conso_or_releve_input.value.replace(/[^\d]+/g, '')
    # delete letters from the input value
  return

# Main
document.addEventListeners 'turbolinks:load', ->
  listenConsoOrReleveInputs() if document.querySelectorAll('.js__conso_or_releve')
