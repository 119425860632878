Routes = require('../routes.js.erb')

listenInvoicingAddress = ->
  invoicing_address_checkbox = document.querySelector('input#subscription_add_invoicing_address')

  invoicing_address_checkbox.eventHandler = ->
    invoicing_address_form = document.querySelector('#invoicing-address-form')
    invoicing_address_inputs = document.querySelector('#invoicing-address-form').querySelectorAll('input, select')

    if invoicing_address_checkbox.checked
      invoicing_address_form.enable()
      for input in invoicing_address_inputs
        input.removeAttribute('disabled')
    else
      invoicing_address_form.disable()
      for input in invoicing_address_inputs
        input.setAttribute('disabled', 'disabled')

  invoicing_address_checkbox.eventHandler()
  invoicing_address_checkbox.addEventListener 'change', invoicing_address_checkbox.eventHandler

listenAccountHolder = ->
  account_holder_checkbox = document.querySelector('input#subscription_add_account_holder_contact')

  account_holder_checkbox.eventHandler = ->
    account_holder_form = document.querySelector('#account-holder-form')
    account_holder_inputs = document.querySelector('#account-holder-form').querySelectorAll('input, select')

    if account_holder_checkbox.checked
      account_holder_form.enable()
      for input in account_holder_inputs
        input.removeAttribute('disabled')
    else
      account_holder_form.disable()
      for input in account_holder_inputs
        input.setAttribute('disabled', 'disabled')

  account_holder_checkbox.eventHandler()
  account_holder_checkbox.addEventListener 'change', account_holder_checkbox.eventHandler

listenCoHolderContact = ->
  co_holder_contact_checkbox = document.querySelector('input#subscription_add_co_holder_contact')

  co_holder_contact_checkbox.eventHandler = ->
    co_holder_contact_form = document.querySelector('#co-holder-contact-form')
    co_holder_contact_inputs = document.querySelector('#co-holder-contact-form').querySelectorAll('input, select')

    if co_holder_contact_checkbox.checked
      co_holder_contact_form.enable()
      for input in co_holder_contact_inputs
        input.removeAttribute('disabled')
    else
      co_holder_contact_form.disable()
      for input in co_holder_contact_inputs
        input.setAttribute('disabled', 'disabled')

  co_holder_contact_checkbox.eventHandler()
  co_holder_contact_checkbox.addEventListener 'change', co_holder_contact_checkbox.eventHandler

listenIndividualStatus = ->
  individual_status = document.querySelector('input#subscription_account_holder_contact_attributes_status_individual')

  individual_status.eventHandler = ->
    company_name = document.querySelector('.subscription_account_holder_contact_company_name')
    siret = document.querySelector('.subscription_account_holder_contact_siret')
    naf = document.querySelector('.subscription_account_holder_contact_naf')
    naf_modal_hint = document.querySelector('#account-holder-form .js-help[data-modal=modal-naf-code]')

    if individual_status.checked
      company_name.disable()
      company_name.setAttribute('disabled', 'disabled')
      naf.disable()
      naf.setAttribute('disabled', 'disabled')
      siret.disable()
      siret.setAttribute('disabled', 'disabled')
      siret.querySelector('label abbr').remove() if siret.querySelector('abbr')
      naf_modal_hint.disable()

  individual_status.eventHandler()
  individual_status.addEventListener 'change', individual_status.eventHandler

listenProfessionalStatus = ->
  professional_status = document.querySelector('input#subscription_account_holder_contact_attributes_status_professional')

  professional_status.eventHandler = ->
    company_name = document.querySelector('.subscription_account_holder_contact_company_name')
    siret = document.querySelector('.subscription_account_holder_contact_siret')
    naf = document.querySelector('.subscription_account_holder_contact_naf')
    naf_modal_hint = document.querySelector('#account-holder-form .js-help[data-modal=modal-naf-code]')

    if professional_status.checked
      company_name.enable()
      company_name.removeAttribute('disabled')
      siret.enable()
      siret.removeAttribute('disabled')
      naf.enable()
      naf.querySelector('input').removeAttribute('disabled')
      siret.querySelector('label').insertAdjacentHTML('beforeend', '<abbr title="required"> *</abbr>') unless siret.querySelector('abbr')
      naf_modal_hint.disable()

  professional_status.eventHandler()
  professional_status.addEventListener 'change', professional_status.eventHandler

listenNGOStatus = ->
  ngo_status = document.querySelector('input#subscription_account_holder_contact_attributes_status_ngo')

  ngo_status.eventHandler = ->
    company_name = document.querySelector('.subscription_account_holder_contact_company_name')
    siret = document.querySelector('.subscription_account_holder_contact_siret')
    naf = document.querySelector('.subscription_account_holder_contact_naf')
    naf_modal_hint = document.querySelector('#account-holder-form .js-help[data-modal=modal-naf-code]')

    if ngo_status.checked
      company_name.enable()
      # NGO contact: SIRET is optional, NAF is required.
      siret.enable()
      siret.querySelector('abbr').remove() if siret.querySelector('abbr')
      naf.enable()
      naf_modal_hint.enable()

  ngo_status.eventHandler()
  ngo_status.addEventListener 'change', ngo_status.eventHandler

listenProNGOCheckbox = ->
  ngo_checkbox = document.querySelector('#subscription_holder_contact_attributes_ngo')
  naf_modal_hint = document.querySelector('.js-holder-contact-company-and-name .js-help[data-modal=modal-naf-code]')

  ngo_checkbox.eventHandler = ->
    ngo_checkbox_input = document.querySelector('input#subscription_holder_contact_attributes_ngo')

    siret = document.querySelector('.subscription_holder_contact_siret label')

    # NGO contact: SIRET is optional, NAF is required.
    if ngo_checkbox_input.checked
      siret.querySelector('abbr')?.remove()
      naf_modal_hint.enable()
    else
      siret.insertAdjacentHTML('beforeend', '<abbr title="required"> *</abbr>')
      naf_modal_hint.disable()

  ngo_checkbox.eventHandler()
  ngo_checkbox.addEventListener 'change', ngo_checkbox.eventHandler

listenMonthlyPayment = ->
  monthly_payment_input = document.querySelector('input#subscription_monthly_payment')

  monthly_payment_input.eventHandler = ->
    monthly_payment_alert = document.querySelector('#monthly-alert')

    if monthly_payment_input.checked
      monthly_payment_alert.enable()
    else
      monthly_payment_alert.disable()

  monthly_payment_input.eventHandler()
  monthly_payment_input.addEventListener 'change', monthly_payment_input.eventHandler

listenWithdrawal = ->
  withdrawal_options = document.querySelector('#withdrawal-options')

  withdrawal_options.eventHandler = ->
    would_like_to_withdraw = document.querySelector('input#subscription_withdrawal_period_true').checked
    conflicting_dates_alert = document.querySelector('#conflicting-dates')

    if would_like_to_withdraw && conflicting_dates_alert
      conflicting_dates_alert.enable()
    else
      conflicting_dates_alert.disable()

  withdrawal_options.eventHandler()
  withdrawal_options.addEventListener 'change', withdrawal_options.eventHandler

listenPaymentType = ->
  payment_type_select = document.querySelector('.subscription_payment_type select')

  payment_type_select.eventHandler = ->
    bank_details = document.querySelector('#bank-details')

    if payment_type_select.value == 'DD'
      bank_details.enable()
    else
      bank_details.disable()

  payment_type_select.eventHandler()
  payment_type_select.addEventListener 'change', payment_type_select.eventHandler

listenDifferentPaymentType = ->
  different_shareholding_payment = document.querySelector('input#subscription_different_shareholding_payment')

  different_shareholding_payment.eventHandler = ->
    payment_type = document.querySelector('#shareholder-payment-type')

    if different_shareholding_payment.checked
      payment_type.enable()
      payment_type.querySelector('select').removeAttribute('disabled')
    else
      payment_type.disable()
      payment_type.querySelector('select').setAttribute('disabled', 'disabled')

  different_shareholding_payment.eventHandler()
  different_shareholding_payment.addEventListener 'change', different_shareholding_payment.eventHandler


listenDiscountCode = ->
  discount_input = document.querySelector '#subscription_discount_code'
  discount_input.eventHandler = ->
    verifyCode(code: discount_input.value)

  delay = null
  discount_input.addEventListener 'input', ->
    clearTimeout delay if delay
    delay = setTimeout ->
      discount_input.eventHandler()
    , 350

  if discount_input.value != ''
    verifyCode(code: discount_input.value)

verifyCode = (options) ->
  xhr = new XMLHttpRequest()
  xhr.open 'GET', Routes.api_v1_discount_codes_path({code: options.code})
  xhr.send(null)

  xhr.onreadystatechange = ->
    DONE = 4
    OK = 200

    if xhr.readyState == DONE
      if xhr.status == OK
        data = JSON.parse(xhr.responseText).data
        enableCode data
      else
        data = JSON.parse(xhr.responseText)
        disableCode data.message


enableCode = (data) ->
  document.querySelector('#discount-code-error').disable()
  document.querySelector('#discount-code-success p').innerHTML = data.attributes.description
  document.querySelector('#discount-code-success').enable()

disableCode = (data) ->
  document.querySelector('#discount-code-success').disable()
  document.querySelector('#discount-code-error p').innerHTML = data
  document.querySelector('#discount-code-error').enable()

listenOfferEndDate = ->
  end_date_input = document.querySelector('#subscription_specific_offer_attributes_end_date')
  sales_offer_target = end_date_input.dataset.salesOfferTarget
  sales_offer_input = document.querySelector("##{ sales_offer_target }")
  client_nature = sales_offer_input.dataset.clientNature

  end_date_input.eventHandler = ->
    loadSalesOffer end_date: end_date_input.value, client_nature: client_nature, input: sales_offer_input
  end_date_input.eventHandler()
  end_date_input.addEventListener 'input', end_date_input.eventHandler

loadSalesOffer = (options) ->
  xhr = new XMLHttpRequest()
  spinner = document.querySelector '.spinner'
  spinner.enable()
  xhr.open 'GET', Routes.api_v1_sales_offer_stores_path(end_date: options.end_date, client_nature: options.client_nature)
  xhr.send(null)

  xhr.onreadystatechange = ->
    spinner.disable()
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      data = JSON.parse(xhr.responseText).data
      clearSalesOfferOptionList input: options.input
      fillSalesOfferOptionList input: options.input, data: data
      Utility.preselectOptionList options.input
      options.input.eventHandler()

clearSalesOfferOptionList = (options) ->
  options.input.options.length = 0
  options.input.disabled = true

fillSalesOfferOptionList = (options) ->
  options.input.appendChild(option = new Option('Choisir', ''))
  for offer in options.data
    options.input.appendChild(option = new Option(offer.attributes.title, offer.attributes.code))

  options.input.value = options.input.dataset.salesOffer
  options.input.disabled = options.data.length <= 0

listenSalesOffer = ->
  sales_offer_input = document.querySelector('#subscription_specific_offer_attributes_sales_offer')
  client_nature = sales_offer_input.dataset.clientNature
  status_code = sales_offer_input.dataset.statusCode
  elec_range = sales_offer_input.dataset.elecRange
  pricing_code = sales_offer_input.dataset.pricingCode
  tarifs_select = document.querySelector('select#subscription_coopener_pricing_code')

  sales_offer_input.eventHandler = ->
    if sales_offer_input.value.length != 0
      loadTarifs sales_offer: sales_offer_input.value, status_code: status_code, elec_range: elec_range, pricing_code: pricing_code, input: tarifs_select
    else
      clearTarifsOptionList input: tarifs_select

  sales_offer_input.addEventListener 'input', sales_offer_input.eventHandler

loadTarifs = (options) ->
  xhr = new XMLHttpRequest()
  spinner = document.querySelector '.spinner'
  spinner.enable()
  xhr.open 'GET', Routes.api_v1_tarifs_path(sales_offer: options.sales_offer, status_code: options.status_code, elec_range: options.elec_range, pricing_code: options.pricing_code)
  xhr.send(null)

  xhr.onreadystatechange = ->
    spinner.disable()
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      data = JSON.parse(xhr.responseText).data
      clearTarifsOptionList input: options.input
      fillTarifsOptionList input: options.input, data: data, sales_offer: options.sales_offer

clearTarifsOptionList = (options) ->
  options.input.options.length = 0
  options.input.disabled = true

fillTarifsOptionList = (options) ->
  subscription_tarif = options.input.dataset.classeTarifaire
  options.input.value = null

  for tarif in options.data.attributes.classes_tarifaires
    options.input.appendChild(option = new Option("#{tarif.name} (#{tarif.code})", tarif.code))
    if tarif.code == subscription_tarif
      options.input.value = subscription_tarif

  options.input.disabled = options.data.length <= 0

# Main
document.addEventListeners 'turbolinks:load', ->
  listenInvoicingAddress() if document.querySelector('input#subscription_add_invoicing_address')
  listenAccountHolder() if document.querySelector('input#subscription_add_account_holder_contact')
  listenCoHolderContact() if document.querySelector('input#subscription_add_co_holder_contact')
  listenProNGOCheckbox() if document.querySelector('#subscription_holder_contact_attributes_ngo')
  listenMonthlyPayment() if document.querySelector('input#subscription_monthly_payment')
  listenWithdrawal() if document.querySelector('#conflicting-dates')
  listenPaymentType() if document.querySelector('.subscription_payment_type')
  listenDifferentPaymentType() if document.querySelector('#shareholder-payment-type')
  if document.querySelector '#subscription-form.billing'
    listenDiscountCode()
    Utility.pushData 'Formulaire de souscription', 'Etapes clefs', 'facturation-site'
  if document.querySelector('#subscription_specific_offer_attributes_sales_offer')
    listenSalesOffer()
    listenOfferEndDate()
  if document.querySelector('.subscription_account_holder_contact_status')
    listenIndividualStatus()
    listenProfessionalStatus()
    listenNGOStatus()
