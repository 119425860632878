class window.RemoteForm
  constructor: (@form, @callbacks) ->
    @form.addEventListener 'ajax:success', @onSuccess
    @form.addEventListener 'ajax:error', @onError
    @form.addEventListener 'ajax:complete', @onComplete

  onSuccess: (e) =>
    data = e.detail[0]
    status = e.detail[1]
    xhr = e.detail[2]
    @callbacks['on_success']?(e, data, status, xhr)

  onError: (e) =>
    error = e.detail[0]
    status = e.detail[1]
    xhr = e.detail[2]
    @callbacks['on_error']?(e, xhr, status, error)

  onComplete: (e) =>
    @callbacks['on_complete']?(e)

Node.prototype.actAsRemoteForm = (callbacks) ->
  new RemoteForm(this, callbacks)
