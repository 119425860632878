document.addEventListener('turbolinks:load', () => {
  const form = document.querySelector('.js_phone_number');

  if (!form) {
    return;
  }

  const phoneNumberSuccess = document.querySelector('.js-phone-number-success');
  const phoneNumberError = document.querySelector('.js-phone-number-error');

  const spinner = document.querySelector('.spinner');

  form.addEventListener('submit', () => {
    phoneNumberSuccess.innerHTML = '';
    phoneNumberError.innerHTML = '';
    spinner.enable();
  });

  form.actAsRemoteForm({
    on_success(e, data) {
      spinner.disable();

      phoneNumberSuccess.innerHTML = data.confirmation_message;
    },
    on_error(e, xhr, status, body) {
      spinner.disable();

      phoneNumberError.innerHTML = body.error;
    },
  });
});
