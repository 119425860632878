# Most of this code is similar to app/assets/javascripts/subscriptions/housing.coffee
listenPDLSearch = ->
  form = document.querySelector('.js_pdl_search')
  pdl_input = form.querySelector('.js_pdl_search__input')

  pdl_result = document.querySelector('.js_pdl_search__result')
  form_error = document.querySelector('.js_pdl_search__form_error')
  alert_unavailable = document.querySelector('.js_pdl_search__api_unavailable')
  alert_api = document.querySelector('.js_pdl_search__api_error')
  next_step_button = document.querySelector('.js_pdl_search__next_step_button')
  api_error_next_step_button = document.querySelector('.js_pdl_search__api_error_next_step_button')
  skip_step_link = document.querySelector('.js_pdl_search__skip_step_link')

  disable_all = ->
    components = [form_error, alert_api, alert_unavailable, skip_step_link]
    components.forEach((component) -> component.disable())
    next_step_button.disabled = true
    pdl_result.innerHTML = ""
    skip_step_link.classList.add("hidden")
    api_error_next_step_button.classList.add("hidden")
    next_step_button.classList.remove("hidden")
    enableMainFormButtons()

  spinner = document.querySelector ".spinner"
  form.addEventListener 'submit', ->
    document.querySelector(".spinner").enable()

  form.actAsRemoteForm

    on_success: (e, data, status, xhr) ->
      disable_all()
      pdl_result.innerHTML = data.querySelector('body').innerHTML
      pdl_result.enable()

      pdl_mismatch_c2c4 = document.querySelector('.js_pdl_search__mismatch_c2c4')
      next_step_button.disabled = false unless pdl_mismatch_c2c4

      pdl_result.scrollIntoView(behavior: 'smooth')
      spinner.disable()
      listenPDLResult()

      Utility.pushData 'Formulaire estimation', 'Recherche avec PDL', 'Succès'

    on_error: (e, xhr, status, body) ->
      disable_all()

      if xhr.status == 503
        alert_unavailable.enable()
        next_step_button.disabled = false

        Utility.pushData 'Formulaire estimation', 'Recherche avec PDL', 'KO Enedis'

      else if body && body.errors
        form_error.enable()
        error_message = document.querySelector(".js_pdl_search__form_error_message")
        error_message.innerHTML = body.errors

        skip_step_link.classList.remove("hidden")

        Utility.pushData 'Formulaire estimation', 'Recherche avec PDL', 'Erreurs Formulaire'

      else
        alert_api.enable()
        api_error_next_step_button.classList.remove("hidden")
        next_step_button.classList.add("hidden")

        Utility.pushData 'Formulaire estimation', 'Recherche avec PDL', '0 résultat Enedis'

      spinner.disable()

  submit_ajax = form.querySelector('[type=submit]')
  submit_ajax.click() if pdl_input.value

listenPDLResult = ->
  pdl_point_id = document.querySelectorAll('[name="point_id"]:not([value="not_my_pdl"])')
  not_my_pdl = document.querySelector('#point_id_not_my_pdl')

  not_my_pdl.addEventListener 'change', ->
    enableSkipFormButtons()

  pdl_point_id.addEventListener 'change', ->
    enableMainFormButtons()

enableMainFormButtons = ->
  main_form_buttons = document.querySelector('.js_pdl_search__main_form_buttons')
  skip_form_buttons = document.querySelector('.js_pdl_search__skip_form_buttons')

  main_form_buttons.classList.remove("hidden")
  skip_form_buttons.classList.add("hidden")

enableSkipFormButtons = ->
  main_form_buttons = document.querySelector('.js_pdl_search__main_form_buttons')
  skip_form_buttons = document.querySelector('.js_pdl_search__skip_form_buttons')

  main_form_buttons.classList.add("hidden")
  skip_form_buttons.classList.remove("hidden")

document.addEventListeners 'turbolinks:load', ->
  if document.querySelector('.js_pdl_search')
    listenPDLSearch()
    window.openPDLInfoModal()
