class window.Slider
  constructor: (@elt, options = {}) ->
    @items_container = @elt.querySelector('[data-slider-items]')
    @items = @items_container.querySelectorAll('[data-slider-item]')

    @left_control = @elt.querySelector('[data-slider-control=left]')
    @right_control = @elt.querySelector('[data-slider-control=right]')

    @slide_value = 0
    @loop = options['loop'] || @elt.dataset.sliderLoop
    @auto = options['auto'] || @elt.dataset.sliderAuto

    @items_per_screen_xs = options['items_per_screen_xs'] || parseInt(@elt.dataset.sliderItemsPerScreenXs, 10) || 1
    @items_per_screen_sm = options['items_per_screen_sm'] || parseInt(@elt.dataset.sliderItemsPerScreenSm, 10) || 1
    @items_per_screen_md = options['items_per_screen_md'] || parseInt(@elt.dataset.sliderItemsPerScreenMd, 10) || 1
    @items_per_screen_lg = options['items_per_screen_lg'] || parseInt(@elt.dataset.sliderItemsPerScreenLg, 10) || 1
    @items_per_screen_xl = options['items_per_screen_xl'] || parseInt(@elt.dataset.sliderItemsPerScreenXl, 10) || 1
    @items_per_screen = Math.max(@items_per_screen_xs, @items_per_screen_sm, @items_per_screen_md, @items_per_screen_lg, @items_per_screen_xl)

    @slide_strategy = options['slide_strategy'] || @elt.dataset.sliderSlideStrategy
    @items_per_slide = if @slide_strategy == 'atomic' then 1 else @items_per_screen
    @transition_delay = options['transition_delay'] || parseInt(@elt.dataset.sliderTransitionDelay, 10) || 350
    @init()

  init: ->
    @left_control.addEventListener 'click', =>
      @slide('left') unless @left_control_disabled

    @right_control.addEventListener 'click', =>
      @slide('right') unless @right_control_disabled

    @toggle_controls()
    @enable_auto_slide() if @auto
    @append_items() if @loop == 'infinite'
    @instant_slide()
    @set_active()

  enable_auto_slide: ->
    if @loop
      @auto_slide()

      @elt.addEventListener 'mouseenter', =>
        clearTimeout @delay

      @elt.addEventListener 'mouseleave', =>
        @auto_slide()

    else
      console.warn("Slider: Can't use `auto' option without `loop'")

  append_items: ->
    for i in [0..@items_per_screen - 1]
      do (i) =>
        @items_container.insertBefore(@items[@items.length - 1 - i].cloneNode(true), @items_container.firstChild)
        @items_container.appendChild(@items[i].cloneNode(true))

    @items = @items_container.querySelectorAll('[data-slider-item]')
    @slide_value = @items_per_screen


  auto_slide: ->
    @delay = setTimeout =>
      @slide('right')
      @auto_slide()
    , @auto

  slide: (direction) ->
    if @loop == 'infinite'
      @calc_infinite_slide_value(direction)
    else if @loop
      @calc_loop_slide_value(direction)
    else
      @calc_slide_value(direction)

    @toggle_controls()

    @eachItems (item) =>
      item.style["transform"] = "translateX(#{ @slide_value * -100 }%)"

    @set_active()
    if @loop == 'infinite'
      setTimeout(@reset_loop, @transition_delay)
      @set_active()

  eachItems: (block) ->
    for item in @items
      do ->
        block(item)

  calc_slide_value: (direction) ->
    if direction == 'left'
      return if @should_disable_left_control()
      @slide_value -= @items_per_slide
    else if direction == 'right'
      return if @should_disable_right_control()
      @slide_value += @items_per_slide

  calc_loop_slide_value: (direction) ->
    if direction == 'left'
      @slide_value -= @items_per_slide
    else if direction == 'right'
      @slide_value += @items_per_slide

    @slide_value = @relative_slide_value()

  calc_infinite_slide_value: (direction) ->
    if direction == 'left'
      @slide_value -= @items_per_slide
    else if direction == 'right'
      @slide_value += @items_per_slide

  relative_slide_value: ->
    @slide_value.mod(@items.length - @items_per_screen + @items_per_slide)

  toggle_controls: ->
    if @should_disable_left_control() then @disable_left_control() else @enable_left_control()
    if @should_disable_right_control() then @disable_right_control() else @enable_right_control()

  should_disable_left_control: ->
    return false if @loop
    @slide_value <= 0

  enable_left_control: ->
    @left_control_disabled = false
    @left_control.enable()

  disable_left_control: ->
    @left_control_disabled = true
    @left_control.disable()

  should_disable_right_control: ->
    return false if @loop
    @slide_value >= @items.length - @items_per_screen

  enable_right_control: ->
    @right_control_disabled = false
    @right_control.enable()

  disable_right_control: ->
    @right_control_disabled = true
    @right_control.disable()

  set_active: ->
    for item in @items
      item.classList.remove 'active'

    for index in [@slide_value..@slide_value + @items_per_screen - 1]
      @items[index].classList.add 'active'

  reset_loop: =>
    if @slide_value >= @items.length - @items_per_screen
      @slide_value = @items_per_screen
      @instant_slide()
    else if @slide_value <= 0
      @slide_value = @items.length - @items_per_screen * 2
      @instant_slide()

  instant_slide: ->
    @eachItems (item) =>
      item.style.transition = 'none'
      item.style["transform"] = "translateX(#{ @slide_value * -100 }%)"
      setTimeout =>
        item.style.transition = "transform #{ @transition_delay }ms ease"
      , 15
