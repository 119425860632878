listenFindByPDL = ->
  form = document.querySelector '#find-by-pdl-form'

  pdl_result = document.querySelector('#find-by-pdl-result')
  pdl_input = form.querySelector('#pdl_store_point_id')
  validation_errors = document.querySelector '#find-by-pdl-validation-errors'
  alert_no_result = document.querySelector '#find-by-pdl-no-result'
  alert_unavailable = document.querySelector '#find-by-pdl-api-unavailable'
  main_form_buttons = document.querySelector '#main-form-buttons'

  show_validation_errors = (errors_array) ->
    validation_errors.querySelector('ul.js-validation-errors-container').innerHTML = errors_array.map((e) => "<li>#{e}</li>").join("")
    validation_errors.enable()

  hide_validation_errors = ->
    validation_errors.querySelector('ul.js-validation-errors-container').innerHTML = ''
    validation_errors.disable()

  spinner = document.querySelector ".spinner"
  spinnerLoad()

  form.actAsRemoteForm
    on_success: (e, data, status, xhr) ->
      spinner.disable()
      pdl_result.innerHTML = data.querySelector('body').innerHTML
      pdl_result.enable()
      alert_no_result.disable()
      hide_validation_errors()
      alert_unavailable.disable()
      main_form_buttons.disable()

      updateByPdlFormUponResults()

      pdl_result.scrollIntoView(behavior: 'smooth')

      Utility.pushData 'Formulaire de souscription', 'Recherche avec PDL', 'Succès'

    on_error: (e, xhr, status, body) ->
      spinner.disable()
      pdl_result.disable()
      main_form_buttons.enable() if !document.querySelector '#find-by-address-api-error'

      if xhr.status == 503
        alert_no_result.disable()
        hide_validation_errors()
        alert_unavailable.enable()

        Utility.pushData 'Formulaire de souscription', 'Recherche avec PDL', 'KO Enedis'

      else if xhr.status == 422
        alert_no_result.disable()
        alert_unavailable.disable()
        show_validation_errors(body.errors)

        Utility.pushData 'Formulaire de souscription', 'Recherche avec PDL', 'Erreurs Formulaire'

      else if xhr.status == 404
        alert_unavailable.disable()
        hide_validation_errors()
        alert_no_result.enable()

        Utility.pushData 'Formulaire de souscription', 'Recherche avec PDL', '0 résultat Enedis'

  submit_ajax = form.querySelector('[type=submit]')
  submit_ajax.click() if pdl_input.value


listenFindByAddress = ->
  form = document.querySelector '#find-by-address-form'

  pdl_result = document.querySelector('#find-by-address-result')
  validation_errors = document.querySelector('#form-validation-error')
  alert_api = document.querySelector '#find-by-address-api-error'
  alert_unavaiblable = document.querySelector '#find-by-address-api-unavailable'
  alert_several_pdls = document.querySelector '#find-by-address-several-pdls'
  main_form_buttons = document.querySelector '#main-form-buttons'

  hide_alerts = ->
    alert_api.disable()
    alert_unavaiblable.disable()
    alert_several_pdls.disable()

  show_validation_errors = (errors_array) ->
    validation_errors.querySelector('ul').innerHTML = errors_array.map((e) => "<li>#{e}</li>").join("")
    validation_errors.enable()

  hide_validation_errors = ->
    validation_errors.querySelector('ul').innerHTML = ''
    validation_errors.disable()

  spinner = document.querySelector ".spinner"
  spinnerLoad()

  form.actAsRemoteForm

    on_success: (e, data, status, xhr) ->
      spinner.disable()
      hide_alerts()

      pdl_result.innerHTML = data.querySelector('body').innerHTML
      pdl_result.enable()
      submit_find_by_address = document.querySelector('#find-by-address-result').querySelector('#submit-pdl-result')
      submit_find_by_address.enable()
      main_form_buttons.disable()
      hide_validation_errors()

      updateByAddressFormUponResults()

      Utility.pushData 'Formulaire de souscription', 'Recherche avec adresse du compteur', 'Succès'

      pdl_result.scrollIntoView(behavior: 'smooth')

    on_error: (e, xhr, status, response_body) ->
      spinner.disable()
      hide_alerts()

      if xhr.status == 503
        hide_validation_errors()
        alert_unavaiblable.enable()
        main_form_buttons.disable()
        alert_unavaiblable.scrollIntoView(behavior: 'smooth')

        Utility.pushData 'Formulaire de souscription', 'Recherche avec adresse du compteur', 'KO Enedis'

      else if xhr.status == 422
        show_validation_errors(response_body.errors)
        main_form_buttons.enable()
        main_form_buttons.scrollIntoView(behavior: 'smooth')

        Utility.pushData 'Formulaire de souscription', 'Recherche avec adresse du compteur', 'Erreurs Formulaire'

      else if xhr.status == 400 && response_body?.error_code == 'SEVERAL_PDLS'
        hide_validation_errors()
        alert_several_pdls.enable()
        main_form_buttons.disable()
        main_form_buttons.scrollIntoView(behavior: 'smooth')

      else if xhr.status == 404
        hide_validation_errors()
        alert_api.enable()
        main_form_buttons.disable()
        alert_api.scrollIntoView(behavior: 'smooth')

        Utility.pushData 'Formulaire de souscription', 'Recherche avec adresse du compteur', '0 résultat Enedis'

      pdl_result.disable()

updateByPdlFormUponResults = ->
  good_result_input = document.querySelector '[name="subscription[pdl_store_attributes][point_id]"]:not([type="hidden"])'

  submit_find_by_pdl = document.querySelector('#find-by-pdl-result').querySelector('#submit-pdl-result')

  good_result_input.addEventListener 'change', ->
    submit_find_by_pdl.removeAttribute("disabled")

  submit_find_by_pdl.setAttribute("disabled","disabled") unless good_result_input.checked

updateByAddressFormUponResults = ->
  other_inputs = document.querySelectorAll('[name="subscription[pdl_store][point_id]"]:not([value=""]):not([type="hidden"])')
  not_my_pdl_input = document.querySelector("#subscription_pdl_store_attributes_missing_point_id_by_address")

  if other_inputs.length == 1
    other_inputs[0].checked = true

  other_inputs_checked = document.querySelector('#find-by-address-result').querySelectorAll('[name="subscription[pdl_store][point_id]"]:not([value=""]):checked')

  submit_find_by_address = document.querySelector('#find-by-address-result').querySelector('#submit-pdl-result')
  submit_buttons = document.querySelector('#find-by-address-result').querySelector('#submit-form')
  skip_submit = document.querySelector('#find-by-address-result').querySelector '#skip-form'

  not_my_pdl_input.addEventListener 'change', ->
    submit_buttons.disable()
    skip_submit.enable()

  for input in other_inputs
    input.addEventListener 'change', ->
      submit_find_by_address.removeAttribute("disabled","disabled")
      submit_buttons.enable()
      skip_submit.disable()

  submit_find_by_address.setAttribute("disabled","disabled") if other_inputs_checked.length == 0

listenFindByPdlGiveUp = ->
  give_up_find_by_pdl = document.querySelector("#give-up-find-by-pdl")
  find_by_pdl_results = document.querySelector("#find-by-pdl-result")
  find_by_address_form = document.querySelector("#find-by-address-form")

  give_up_find_by_pdl.addEventListener 'click', ->
    give_up_find_by_pdl.disable()
    find_by_pdl_results.disable()
    find_by_address_form.enable()

spinnerLoad = ->
  submit_buttons = document.querySelectorAll('[type=submit]')
  for submit_button in submit_buttons
    submit_button.addEventListener 'click', ->
      document.querySelector(".spinner").enable()

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector '#find-by-pdl-form'
    listenFindByPDL()
    window.openPDLInfoModal()
  listenFindByAddress() if document.querySelector '#find-by-address-form'
  if document.querySelector("#give-up-find-by-pdl")
    listenFindByPdlGiveUp()
  if document.querySelector '#subscription-form.housing'
    Utility.pushData 'Formulaire de souscription', 'Etapes clefs', 'information-site'
