/* eslint no-console:0 */
require.context('../images', true);

const Rails = require('@rails/ujs');

Rails.start();

const Turbolinks = require('turbolinks');

Turbolinks.start();

require('../javascript/shared/scroll_listener');
require('../javascript/shared/klaro-config');

require('../javascript/formulaire/routes.js.erb');

require('../javascript/formulaire/utilities');
require('../javascript/formulaire/components');

require('../javascript/formulaire/subscriptions/billing');
require('../javascript/formulaire/subscriptions/client_status');
require('../javascript/formulaire/subscriptions/errors');
require('../javascript/formulaire/subscriptions/housing');
require('../javascript/formulaire/subscriptions/housing_new_site');
require('../javascript/formulaire/subscriptions/onboarding');
require('../javascript/formulaire/subscriptions/shareholding');
require('../javascript/formulaire/subscriptions/summary');
require('../javascript/formulaire/subscriptions/technical_data');

require('../javascript/formulaire/estimations/pdl_search');
require('../javascript/formulaire/estimations/result');
require('../javascript/formulaire/estimations/power_recommendation_chart');

require('../stylesheets/formulaire/application.sass');

require('../javascript/shared/stimulus');
