Routes = require('../routes.js.erb')

listenClasseTarifaire = ->
  classe_tarifaire_input = document.querySelector('.classe-tarifaire-select')
  desired_power_input = document.querySelector('.desired-power-select')

  classe_tarifaire_input.eventHandler = ->
    if classe_tarifaire_input.value.length != 0
      loadDesiredPower pricing_code: classe_tarifaire_input.value, input: desired_power_input
  classe_tarifaire_input.eventHandler()
  classe_tarifaire_input.addEventListener 'change', classe_tarifaire_input.eventHandler

loadDesiredPower = (options) ->
  xhr = new XMLHttpRequest()
  spinner = document.querySelector '.spinner'
  spinner.enable()
  xhr.open 'GET', Routes.api_v1_tarif_path(pricing_code: options.pricing_code)
  xhr.send(null)

  xhr.onreadystatechange = ->
    spinner.disable()
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      data = JSON.parse(xhr.responseText).data
      clearDesiredPowerOptionList input: options.input
      fillDesiredPowerOptionList input: options.input, data: data

clearDesiredPowerOptionList = (options) ->
  options.input.options.length = 0
  options.input.disabled = true

fillDesiredPowerOptionList = (options) ->
  prompt_option = options.input.appendChild(option = new Option('Choisir', ''))
  for power in options.data.attributes.classes_tarifaires[0].power_list
    options.input.appendChild(option = new Option(power, power))

  desired_power = options.input.dataset.desiredPower
  options.input.value = desired_power || ""

  options.input.disabled = options.data.length <= 0

listenTarifsSelect = ->
  select_tarifs_input = document.querySelector('.classe-tarifaire-select')

  select_tarifs_input.eventHandler = ->
    selected_value = select_tarifs_input.querySelector('option:checked').getAttribute('value')
    tooltip = document.querySelector('.tooltip')

    if selected_value
      active_tarif = document.querySelector("##{ selected_value }")
      active_tarif.enable()
      tooltip.enable() if tooltip
      active_inputs = document.querySelectorAll("##{ active_tarif.id } input")

      for input in active_inputs
        input.removeAttribute('disabled')

      inactive_tarifs = document.querySelectorAll(".releve-or-conso-input:not(##{ active_tarif.id }), .puissances_input:not(##{ active_tarif.id })")
      for inactive_tarif in inactive_tarifs
        inactive_tarif.disable()
        inactive_inputs = document.querySelectorAll("##{ inactive_tarif.id } input")
        for input in inactive_inputs
          input.setAttribute('disabled', 'disabled')
    else
      inactive_tarifs = document.querySelectorAll(".releve-or-conso-input, .puissances_input")
      for inactive_tarif in inactive_tarifs
        inactive_tarif.disable()

  select_tarifs_input.eventHandler()
  select_tarifs_input.addEventListener 'select-pricing-code', select_tarifs_input.eventHandler
  select_tarifs_input.addEventListener 'change', select_tarifs_input.eventHandler

# Main
document.addEventListeners 'turbolinks:load', ->
  listenClasseTarifaire() if (document.querySelector '.classe-tarifaire-select') && document.querySelector('.desired-power-select')
  listenTarifsSelect() if document.querySelector('.classe-tarifaire-select') && (document.querySelector('.releve-or-conso-input') || document.querySelector('.puissances_input'))
