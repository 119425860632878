Routes = require('../routes.js.erb')

listenDates = ->
  start_date = document.getElementById('subscription_installation_start_date')
  end_date = document.getElementById('subscription_removal_end_date')
  date_inputs = [start_date, end_date]
  alert = document.querySelector '#max-one-year'
  pdl = document.querySelector '#pdl-store'

  for input in date_inputs
    do ->
      input.eventHandler = ->
        if start_date.value
          start_date_value = toEuropeanDate(start_date.value)

        if end_date.value
          end_date_value = toEuropeanDate(end_date.value)

        purpose = document.querySelector('.subscription_purpose label')

        if numberOfDays(start_date_value, end_date_value) > 365
          alert.enable()
          pdl.disable()
          purpose.querySelector('abbr').remove() if purpose.querySelector('abbr')
        else if numberOfDays(start_date_value, end_date_value) > 28
          purpose.insertAdjacentHTML('beforeend', '<abbr title="required"> *</abbr>') unless purpose.querySelector('abbr')
          pdl.enable()
          alert.disable()
        else
          purpose.querySelector('abbr').remove() if purpose.querySelector('abbr')
          pdl.disable()
          alert.disable()

      input.eventHandler()
      input.addEventListener 'date-changed', input.eventHandler

listenInstallationStartDate = ->
  start_date = document.querySelector('#subscription_installation_start_date')
  alert = document.querySelector('#out-of-range-date')

  start_date.eventHandler = ->
    if start_date.value
      start_date_value = toEuropeanDate(start_date.value)
      current_date = new Date().setHours(0,0,0,0)
      days_interval = numberOfDays(current_date, start_date_value)

      if days_interval < 1 || days_interval > 42
        alert.enable()
      else
        alert.disable()

  start_date.eventHandler()
  start_date.addEventListener 'date-changed', start_date.eventHandler

listenDomaineDeTensionInput = ->
  input_domaine_de_tension = document.querySelector('#subscription_domaine_de_tension')
  display_segment_input(input_domaine_de_tension.value)

listenDomaineDeTensionSelect = ->
  select_domaine_de_tension = document.querySelector('#subscription_domaine_de_tension')
  classe_tarifaire_input = document.querySelector('.classe-tarifaire-select')

  select_domaine_de_tension.eventHandler = ->
    selected_value = select_domaine_de_tension.querySelector('option:checked').getAttribute('value')

    if selected_value
      display_segment_input(selected_value)
      status_code = select_domaine_de_tension.dataset.statusCode
      sales_offer = select_domaine_de_tension.dataset.salesOffer
      loadClasseTarifaire status_code: status_code, sales_offer: sales_offer, elec_range: selected_value, input: classe_tarifaire_input
    else
      clearClasseTarifaireOptionList input: classe_tarifaire_input

  select_domaine_de_tension.eventHandler()
  select_domaine_de_tension.addEventListener 'change', select_domaine_de_tension.eventHandler

display_segment_input = (domaine_de_tension_value) ->
  segment_input = document.querySelector('.segment-input')
  if domaine_de_tension_value == 'HTA'
    segment_input.enable()
  else
    segment_input.disable()

loadClasseTarifaire = (options) ->
  xhr = new XMLHttpRequest()
  spinner = document.querySelector '.spinner'
  spinner.enable()
  xhr.open 'GET', Routes.api_v1_tarifs_path(status_code: options.status_code, sales_offer: options.sales_offer, elec_range: options.elec_range)
  xhr.send(null)

  xhr.onreadystatechange = ->
    spinner.disable()
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      data = JSON.parse(xhr.responseText).data
      clearClasseTarifaireOptionList input: options.input
      fillClasseTarifaireOptionList input: options.input, data: data
      if onlyOneClasseTarifaire data
        options.input.setAttribute('data-preselect', data.attributes["classes_tarifaires"][0]["code"])
        Utility.preselectOptionList options.input
        options.input.eventHandler()

onlyOneClasseTarifaire = (data) ->
  data.attributes.classes_tarifaires.length == 1

clearClasseTarifaireOptionList = (options) ->
  if options.input
    options.input.options.length = 0
    options.input.disabled = true

fillClasseTarifaireOptionList = (options) ->
  prompt_option = options.input.appendChild(option = new Option('Choisir', ''))
  for classe_tarifaire in options.data.attributes.classes_tarifaires
    options.input.appendChild(option = new Option(classe_tarifaire.name, classe_tarifaire.code))

  data_classe_tarifaire = options.input.dataset.classeTarifaire

  if data_classe_tarifaire && (data_classe_tarifaire in options.data.attributes.classes_tarifaires.map (classe_tarifaire) -> classe_tarifaire.code)
    options.input.value = data_classe_tarifaire
  else
    options.input.value = ""

  options.input.dispatchEvent(new CustomEvent 'select-pricing-code')

  options.input.disabled = options.data.length <= 0

listenTechnicalContact = ->
  technical_contact_checkbox = document.querySelector('input#subscription_add_technical_contact')

  technical_contact_checkbox.eventHandler = ->
    technical_contact_form = document.querySelector('#technical-contact-form')
    technical_contact_inputs = document.querySelector('#technical-contact-form').querySelectorAll('input, select')

    if technical_contact_checkbox.checked
      technical_contact_form.enable()
      for input in technical_contact_inputs
        input.removeAttribute('disabled')
    else
      technical_contact_form.disable()
      for input in technical_contact_inputs
        input.setAttribute('disabled', 'disabled')

  technical_contact_checkbox.eventHandler()
  technical_contact_checkbox.addEventListener 'change', technical_contact_checkbox.eventHandler

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector('input#subscription_removal_end_date')
    listenDates()
  else if document.querySelector('input#subscription_installation_start_date')
    listenInstallationStartDate()
  listenTechnicalContact() if document.querySelector('input#subscription_add_technical_contact')
  listenDomaineDeTensionSelect() if document.querySelector('select#subscription_domaine_de_tension')
  listenDomaineDeTensionInput() if document.querySelector('input#subscription_domaine_de_tension')
