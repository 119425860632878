class window.Modal
  @close_all: ->
    for elt in document.querySelectorAll('[data-__modal__]')
      elt.modal.close()

  constructor: (@elt, options = {}) ->
    @name = options['name'] || @elt.id

    @content = options['content'] || document.querySelector("[data-modal-content=#{ @name }]")?.innerHTML
    @open_btn = options['open_btn'] || document.querySelector("[data-modal-open=#{ @name }]")

    @body = options['body'] || @elt.querySelector(".modal-body")
    @close_btn = options['close_btn'] || @elt.querySelectorAll(".js-close-modal")

    @init()

    @elt.modal = this
    @elt.setAttribute 'data-__modal__', true

  init: ->
    if @open_btn
      @open_btn.addEventListener 'click', (evt) =>
        evt.preventDefault()
        @toggle()

    if @close_btn
      @close_btn.addEventListener 'click', (evt) =>
        evt.preventDefault()
        @close(animation: true)

  open: ->
    @constructor.close_all()

    @body.innerHTML = @content if @content
    @elt.setAttribute 'data-active-modal', @name
    @elt.enable()
    if @open_btn && @open_btn.classList.contains("js-help")
      Utility.pushData 'Formulaire de souscription', 'Aide Champs', @name

  close: (options = {}) ->
    if options.animation
      @elt.classList.add('modal-closing')
      setTimeout =>
        @elt.disable()
        @elt.classList.remove('modal-closing')
      , 350
    else
      @elt.disable()

  toggle: ->
    if @elt.classList.contains('active') && @elt.dataset.activeModal == @name
      @close()
    else
      @open()
