listenSliderArrow = ->
  backward = document.querySelectorAll('label[for="12"].carousel-control-backward')

  backward.eventHandler = ->
    Utility.pushData 'Formulaire de souscription', 'Fleche Slider', ''

  backward.addEventListener 'click', backward.eventHandler

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector '#sidebar-reasons'
    listenSliderArrow()
