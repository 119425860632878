const enableCheckbox = (label, input) => {
  input.removeAttribute('disabled');
  label.classList.remove('disabled');
};

const disableCheckbox = (label, input) => {
  input.setAttribute('disabled', 'disabled');
  const checkboxInput = input;
  checkboxInput.checked = false;
  label.classList.add('disabled');
};

const updateSum = (numberOfParts) => {
  document.querySelector('#display_shareholder_sum').textContent = (numberOfParts * 100).toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });
};

const listenShareholdingNumber = () => {
  const input = document.querySelector('input#subscription_shares');

  input.eventHandler = () => {
    const numberOfParts = input.value;
    const submit = document.querySelector('[name="commit"]');

    const shareholdingAgreementLabel = document.querySelector('label[for=subscription_shareholding_agreement]');
    const shareholdingAgreementInput = document.querySelector('input[type=checkbox]#subscription_shareholding_agreement');
    const personalDataAgreementLabel = document.querySelector('label[for=subscription_personal_data_agreement]');
    const personalDataAgreementInput = document.querySelector('input[type=checkbox]#subscription_personal_data_agreement');

    if (numberOfParts.match(/^\d+$/) && (numberOfParts !== '0')) {
      updateSum(numberOfParts);
      submit.value = 'Confirmer';
      enableCheckbox(shareholdingAgreementLabel, shareholdingAgreementInput);
      enableCheckbox(personalDataAgreementLabel, personalDataAgreementInput);
    } else {
      updateSum(0);
      submit.value = 'Continuer';
      disableCheckbox(shareholdingAgreementLabel, shareholdingAgreementInput);
      disableCheckbox(personalDataAgreementLabel, personalDataAgreementInput);
    }
  };

  input.addEventListener('focusout', () => { input.value ||= '0'; });

  input.eventHandler();
  input.addEventListener('input', input.eventHandler);
};

document.addEventListeners('turbolinks:load', () => {
  if (document.querySelector('#subscription-form.shareholding input#subscription_shares')) {
    listenShareholdingNumber();
  }
});
