class window.Datepicker
  constructor: (@options = {}) ->
    if @options.selected_date
      @date = toEuropeanDate(@options.selected_date)
      @selected_date = new Date(@date)
    else
      @date = new Date()

    @date.setHours(0,0,0,0)
    @todaysDate = new Date()
    @todaysDate.setHours(0,0,0,0)
    @activeDates = null

    @elt = document.querySelector("[data-datepicker-for=#{@options.name}]")
    @month = @elt.querySelector('[data-calendar-area="month"] ')
    @next = @elt.querySelector('[data-calendar-toggle="next"]')
    @previous = @elt.querySelector('[data-calendar-toggle="previous"]')
    @label = @elt.querySelector('[data-calendar-label="month"]')

    @date.setDate(1)

    @createMonth()
    @createListeners()
    @openDatepicker()

  createListeners: ->
    @next.addEventListener 'click', =>
      @clearCalendar()
      @date.setMonth(@date.getMonth() + 1)
      @createMonth()

    @previous.addEventListener 'click', =>
      @clearCalendar()
      @date.setMonth(@date.getMonth() - 1)
      @createMonth()

  createDay: (num, day, year) ->
    newDay = document.createElement('div')
    newDay.className = 'vcal-date'
    newDay.setAttribute('data-calendar-date', @date)

    dateEl = document.createElement('span')
    dateEl.innerHTML = num

    if num == 1
      if day == 0
        newDay.style.marginLeft = (6 * 14.28) + '%'
      else
        newDay.style.marginLeft = ((day - 1) * 14.28) + '%'

    date_str = @date.toLocaleDateString('fr-CA') # This formats the date to YYYY-MM-DD
    if @options.disableDatesBefore && date_str < @options.disableDatesBefore
      newDay.classList.add('vcal-date--disabled')
    else
      newDay.classList.add('vcal-date--active')
      newDay.setAttribute('data-calendar-status', 'active')

    if @date.toString() == @todaysDate.toString()
      newDay.classList.add('vcal-date--today')

    if @selected_date
      if @date.toString() == @selected_date.toString()
        newDay.classList.add('vcal-date--selected')

    newDay.appendChild dateEl
    @month.appendChild newDay

  dateClicked: ->
    @activeDates = @elt.querySelectorAll('[data-calendar-status="active"]')
    input = document.querySelector "input##{@options.name}"
    _this = @

    for activeDate in @activeDates
      do ->
        activeDate.addEventListener 'click', ->
          date = new Date this.dataset.calendarDate
          input.value = date.toLocaleDateString('fr-FR') # This formats the date to DD/MM/YYYY
          _this.removeActiveClass()
          _this.elt.disable()
          this.classList.add('vcal-date--selected')
          input.dispatchEvent(new CustomEvent 'date-changed')

  createMonth: ->
    currentMonth = @date.getMonth()

    while @date.getMonth() == currentMonth
      @createDay(
        @date.getDate(),
        @date.getDay(),
        @date.getFullYear()
      )
      @date.setDate(@date.getDate() + 1)

    # while loop trips over and day is at 30/31, bring it back
    @date.setDate(1)
    @date.setMonth(@date.getMonth() - 1)
    @label.innerHTML = @monthsAsString(@date.getMonth()) + ' ' + @date.getFullYear()
    @dateClicked()

  monthsAsString: (monthIndex) ->
    [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ][monthIndex]

  clearCalendar: ->
    @month.innerHTML = ''

  openDatepicker: ->
    input = document.querySelector('input#' + @options.name)
    input.addEventListener "focus", =>
      @elt.enable()
      @closeDatePicker()

  closeDatePicker: ->
    datepicker_elements = document.querySelector("div.#{@options.name}")
    window.addEventListener 'click', (e) =>
      @elt.disable() if !datepicker_elements.contains(e.target)


  removeActiveClass: ->
    for activeDate in @activeDates
      do ->
        activeDate.classList.remove('vcal-date--selected')
