import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineController,
  PointElement,
  LineElement,
} from 'chart.js';

const COLORS = {
  deepBlue: 'rgba(29, 113, 184, 1)', // matches $secondary-color-light-blue
  lightBlue: 'rgba(29, 113, 184, 1)',
  transparent: 'rgba(0, 0, 0, 0)',
};

function createPowerRecommendationChart(canvas) {
  const dates = JSON.parse(canvas.dataset.dates);
  const maxPowers = JSON.parse(canvas.dataset.max_powers);
  const subscribedPower = Number(canvas.dataset.subscribed_power);
  const recommendedPower = Number(canvas.dataset.recommended_power);

  // eslint-disable-next-line max-len
  Chart.register(CategoryScale, LinearScale, BarController, BarElement, LineController, PointElement, LineElement);

  // eslint-disable-next-line no-new
  new Chart(
    canvas,
    {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            data: maxPowers,
            backgroundColor: COLORS.lightBlue,
            label: 'Votre usage*',
            type: 'bar',
            order: 0,
            borderWidth: 0,
            barThickness: 2,
          },
          {
            type: 'line',
            data: [{
              x: dates[0],
              y: subscribedPower,
            }, {
              x: dates[dates.length - 1],
              y: subscribedPower,
            }],
            label: `Puissance actuelle : ${subscribedPower} kVA`,
            backgroundColor: '#2F4F4F',
            pointBackgroundColor: '#2F4F4F',
            borderWidth: 1,
            borderColor: '#2F4F4F',
            fill: false,
            radius: 0.5,
            order: 2,
          },
          {
            type: 'line',
            data: [{
              x: dates[0],
              y: recommendedPower,
            }, {
              x: dates[dates.length - 1],
              y: recommendedPower,
            }],
            label: `Puissance recommandée : ${recommendedPower} kVA`,
            backgroundColor: COLORS.transparent,
            borderColor: '#00a18c',
            borderWidth: 2,
            pointStyle: 'circle',
            pointBackgroundColor: COLORS.deepBlue,
            fill: false,
            radius: 0.5,
            order: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              mirror: true,
              maxRotation: 0,
              callback(value) {
                // The dates are in this format: "mai:2020-05-24"
                // This allows having different values for each day,
                // which makes the chart work normally
                // This split allows showing only the month in the ticks on the scale.
                return value.split(':')[0];
              },
            },
            gridLines: { display: false },
            beginAtZero: true,
          },
          y: {
            max: (subscribedPower + 1),
            ticks: {
              stepSize: 1,
            },
          },
        },
        hover: { mode: null },
        plugins: {
          tooltip: { enabled: false },
          legend: {
            labels: {
              fontFamily: "'Montserrat', 'Helvetica', 'Arial', 'sans-serif'",
              fontColor: 'black',
            },
            position: 'bottom',
            reverse: true,
          },
        },
      },
    },
  );
}

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.js-powerRecommendationChart').forEach((canvas) => {
    createPowerRecommendationChart(canvas);
  });
});
