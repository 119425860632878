Routes = require('../routes.js.erb')
import coopener_email_regex from "../../shared/coopener_email_regex"

modeAdminClientConnectButton = ->
  document.querySelector('#signed-in-login-btn')
modeClientConnectButton = ->
  document.querySelector('#signed-out-login-btn')
logInMessage = ->
  document.querySelector('#login-message')
logOutButton = ->
  document.querySelector('#logout-btn')
logOutMessage = ->
  document.querySelector('#login-success')
logInForm = ->
  document.querySelector('#login-form')
loginModal = ->
  document.querySelector('#login-modal').modal
mainEmailField = ->
  document.querySelector('#subscription_holder_contact_attributes_email')
companyNameField = ->
  document.querySelector('#subscription_holder_contact_attributes_company_name')
loginModalEmailField = ->
  document.querySelector('#login_email')
loginSuccessMessage = ->
  document.querySelector('#success-modal-message')
loginErrorMessage = ->
  document.querySelector('#login-error')
adminLoginErrorMessage = ->
  document.querySelector('#admin-login-error')

modeAdminClientNotConnected = ->
  modeAdminClientConnectButton() != null

modeClientClientNotConnected = ->
  modeClientConnectButton() != null

listenStatus = ->
  individual_status = document.querySelector('#subscription_holder_contact_attributes_status_individual')
  pro_status = document.querySelector('#subscription_holder_contact_attributes_status_professional')
  specific_pro_status = document.querySelector('#subscription_holder_contact_attributes_status_specific_professional')
  status = document.querySelectorAll('[name="subscription[holder_contact_attributes][status]"]')

  status.eventHandler = ->
    public_client = document.querySelector('#public-client')
    if public_client
      if individual_status.checked
        public_client.disable()
      else if pro_status.checked || specific_pro_status.checked
        public_client.enable()

  status.eventHandler()
  status.addEventListener 'change', status.eventHandler

listenEmail = ->
  mainEmailField().eventHandler = ->
    disableLogin()
    verifyEmail(
      email: mainEmailField().value)

    if mainEmailField().value.match(coopener_email_regex)
      saveEmail(
        email: mainEmailField().value,
        token: mainEmailField().dataset.token)

  delay = null
  mainEmailField().addEventListener 'input', ->
    if delay
      clearTimeout delay
    delay = setTimeout ->
      mainEmailField().eventHandler()
    , 350

  if (mainEmailField().value != '') && (!logOutButton())
    verifyEmail(
      email: mainEmailField().value)

  if logOutButton()
    loggedIn()

saveEmail = (options) ->
  xhr = new XMLHttpRequest()
  xhr.open 'GET', Routes.api_v1_contact_update_path({
    token: options.token,
    email: options.email
  })
  xhr.send(null)

verifyEmail = (options) ->
  xhr = new XMLHttpRequest()
  xhr.open 'GET', Routes.api_v1_verify_login_path({ email: options.email })
  xhr.send(null)
  loginModalEmailField().value = mainEmailField().value

  xhr.onreadystatechange = ->
    DONE = 4
    OK = 200
    if xhr.readyState == DONE
      if xhr.status == OK
        enableLogin()

loggedIn = ->
  logInMessage().disable()
  mainEmailField().setAttribute('readonly', 'true')

enableLogin = () ->
  if modeAdminClientNotConnected()
    logInMessage().enable()
  else
    loginModal().open()
    loginModalEmailField().value = mainEmailField().value
    logInForm().enable()

disableLogin = ->
  mainEmailField().removeAttribute('readonly')
  logOutMessage().disable()

  if modeAdminClientNotConnected()
    logInMessage().disable()
  else
    logInMessage().enable()
    loginSuccessMessage().disable()

listenLogin = ->
  if modeClientClientNotConnected()
    listenModeClientLogin()
  if modeAdminClientNotConnected()
    listenModeAdminLogin()

listenModeClientLogin = ->
  modeClientConnectButton().addEventListener 'click', -> logInForm().enable()

  logInForm().actAsRemoteForm
    on_success: (e, data, status, xhr) ->
      loggedIn()
      loginErrorMessage().disable()
      mainEmailField().value = loginModalEmailField().value

      closeModal = ->
        loginModal().close()

      window.setTimeout(closeModal, 3000)

      logInForm().disable()
      loginSuccessMessage().enable()

      logOutMessage().innerHTML = data.querySelector('body').innerHTML
      logOutMessage().enable()
      listenLogout()

    on_error: (e, xhr, status, error) ->
      logOutMessage().disable()
      loginErrorMessage().enable()
      logInMessage().enable()

listenModeAdminLogin = ->
  modeAdminClientConnectButton().addEventListener 'click', ->
    xhr = new XMLHttpRequest()
    xhr.open('POST', Routes.signed_in_sessions_path(), true)
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    xhr.send("login[email]=#{ mainEmailField().value }")

    xhr.onreadystatechange = ->
      DONE = 4
      OK = 200
      if xhr.readyState == DONE
        if xhr.status == OK
          logOutMessage().innerHTML = xhr.responseText
          logOutMessage().enable()
          logInMessage().disable()
          adminLoginErrorMessage().disable()
          mainEmailField().setAttribute('readonly', 'true')
          listenLogout()
        else
          adminLoginErrorMessage().enable()

listenLogout = ->
  if logOutButton()
    logOutButton().addEventListener 'ajax:success', (e) ->
      # we must empty the "company name" field on deconnexion
      companyNameField().value = ''
      # reload globally the page to have a consistent state with the civility, first name and last name inputs.
      # This is not userfriendly, but this part will be refactored soon (welcome turbolinks 8 ?)
      window.location.reload()

# Main
document.addEventListeners 'turbolinks:load', ->
  if document.querySelector '#subscription-form.client-status'
    listenEmail()
    listenLogin()
    listenLogout()
    listenStatus()

    Utility.pushData 'Formulaire de souscription', 'Etapes clefs', 'situation-site'
