// This component allows toggling the disabled state of a button with a checkbox
// To use it, simply add the query selector of the button as the
// "data-checkbox-for-button" attribute of the checkbox
//
// example:
// <input type="checkbox" data-checkbox-for-button="#confirmation-button">
// <button id="confirmation-button">OK</button>
//
import onLoadAsync from '../on_load_async';

const attachCheckboxesToButtons = () => {
  document.querySelectorAll('[data-checkbox-for-button]:not(.attachedCheckboxesToButtons)').forEach((checkbox) => {
    const button = document.querySelector(checkbox.dataset.checkboxForButton);

    const syncButtonWithCheckBox = () => {
      if (checkbox.checked) {
        button.removeAttribute('disabled');
      } else {
        button.setAttribute('disabled', true);
      }
    };

    syncButtonWithCheckBox();
    checkbox.addEventListener('change', syncButtonWithCheckBox);
    checkbox.classList.add('attachedCheckboxesToButtons');
  });
};

document.addEventListener('turbolinks:load', attachCheckboxesToButtons);
onLoadAsync(attachCheckboxesToButtons);
