errorsFields = ->
  state = document.querySelector('body').dataset.state
  error_messages = document.querySelectorAll('#errors ul li')
  error_fields = document.querySelectorAll('.field_with_errors')

  for message, index in error_messages
    Utility.pushData state, error_fields[index].lastChild.value, message.innerText

# Main
document.addEventListeners 'turbolinks:load', ->
  errorsFields() if document.querySelector('.field_with_errors')
