window.numberOfDays = (start_date, end_date) ->
  Math.round(Math.abs((start_date - end_date)/(1000*60*60*24)))

window.addDays = (date, days) ->
  result = new Date(date)
  result.setDate(result.getDate() + days)

window.toEuropeanDate = (date) ->
  date_split = date.split('/')
  new Date(date_split[2], date_split[1] - 1, date_split[0])
