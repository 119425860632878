document.addEventListener('turbolinks:load', () => {
  const input = document.querySelector('.js-alert-info-4-cadrans-input');

  function updateAlertInfo() {
    const { nbCadrans } = input.selectedOptions[0].dataset;

    const alertInfo4cadrans = document.querySelector('.js-alert-info-4-cadrans');

    if (nbCadrans === '4') {
      alertInfo4cadrans.enable();
    } else {
      alertInfo4cadrans.disable();
    }
  }

  if (input) {
    input.addEventListener('change', updateAlertInfo);
    updateAlertInfo();
  } else {
    const alertInfo4cadrans = document.querySelector('.js-alert-info-4-cadrans');

    const nbCadransPdlTrouve = document.querySelector('#pdl-trouve-nb-cadrans')?.dataset?.nbCadrans;

    if (nbCadransPdlTrouve === '4') {
      alertInfo4cadrans.enable();
    }
  }
});
