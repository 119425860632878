eldCallback = (node) ->
  if (node.selectedOptions.length != 0) && (node.selectedOptions[0].dataset.eld == "true")
    modal = document.querySelector("#eld-modal")

    new Modal(modal, {name: 'eld-modal'})
    document.querySelector('#eld-modal').modal.open()

eldInit = (node) ->
  node.afterFillCities = ->
    eldCallback(node)

# Main
document.addEventListeners 'turbolinks:load', ->
  new LiveEvent 'change', '.city select', eldCallback, eldInit
