listenRadio = ->
  radio_labels = document.querySelectorAll '.radio_buttons:not(.required) label.collection_radio_buttons'

  for radio_label in radio_labels
    radio_input = document.querySelector "##{ radio_label.getAttribute 'for' }"
    do (radio_label, radio_input) ->
      radio_label.addEventListener 'click', (e) ->
        if radio_input.checked
          e.preventDefault()
          radio_input.checked = false
      
# Main
document.addEventListeners 'turbolinks:load', ->
  listenRadio()
