import onLoadAsync from '../../shared/on_load_async';

onLoadAsync =>
  modal_triggers = document.querySelectorAll '[data-modal]'
  for trigger in modal_triggers
    do (trigger) ->
      name = trigger.dataset.modal
      modal = document.querySelector("#{ trigger.dataset.modalTarget }")
      modal ||= document.querySelector("##{ name }")

      new Modal(modal, {name: name, open_btn: trigger}) if modal
